import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { animated, useSpring } from 'react-spring';
import { Spring } from 'react-spring/renderprops';

import Icon from '../Icon';
import { AvailableColors } from '../ThemeProvider';

import * as S from './styles';

const Collapsible = ({
  children,
  title,

  variant,
  padding,
  color,
  className,
}) => {
  const [visible, setVisible] = useState(false);
  const styleIcon = useSpring({
    transform: visible ? 'scale(1.2) rotate(-180deg)' : 'scale(1.5) rotate(0deg)',
  });

  return (
    <S.Collapsible $color={color} className={className}>
      <S.Header onClick={() => setVisible(!visible)} variant={variant}>
        {
          typeof title === 'string' ? (
            <S.Title variant={variant}>{title}</S.Title>
          ) : title
        }
        <S.IconWrapper style={styleIcon}>
          <Icon name='down' color='inherit' size={10} />
        </S.IconWrapper>
      </S.Header>

      <Spring from={{ height: 0 }} to={{ height: visible ? 'auto' : 0 }}>
        {({ height }) => (
          <animated.div style={{ height, overflow: 'hidden' }}>
            <S.Content $padding={padding}>
              {children}
            </S.Content>
          </animated.div>
        )}
      </Spring>

    </S.Collapsible>
  );
};

Collapsible.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.node.isRequired,
  color: PropTypes.oneOf(AvailableColors),
  variant: PropTypes.oneOf(['contained', 'clean']),
};

Collapsible.defaultProps = {
  variant: 'contained',
  padding: true,
  color: 'primary',
};

export const CollapsibleGroup = ({
  children,
  className,
}) => {
  return (
    <S.CollapsibleGroup className={className}>
      {children}
    </S.CollapsibleGroup>
  )
};

CollapsibleGroup.propTypes = {
  children: PropTypes.arrayOf(Collapsible).isRequired,
};

export { S as CollapsibleStyles };
export default Collapsible;
