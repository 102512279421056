import React from 'react';
import PropTypes from 'prop-types';
import Collapsible, { CollapsibleGroup } from 'ui/elements/Collapsible';
import OpticalHeading from 'ui/elements/Heading/OpticalHeading';

import { Widget } from './styles';
import ContentBox from './ContentBox';

const Accordion = ({
  items,
  title,
  variant,
}) => {
  return (
    <Widget>
      <OpticalHeading level={3}>{title}</OpticalHeading>
      <CollapsibleGroup>
        {
          items.map((item) => (
            <Collapsible
              key={item._key}
              variant={variant.toLowerCase()}
              title={item.headline}
            >
              <ContentBox blocks={item.text} />
            </Collapsible>
          ))
        }
      </CollapsibleGroup>
    </Widget>
  )
};

Accordion.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
};

Accordion.defaultProps = {
  variant: 'contained',
}

export default Accordion;
