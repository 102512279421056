import styled from 'styled-components';
import { animated } from 'react-spring';

const InnerPadding = 16;

export const Title = styled.div``;

export const Header = styled.header`
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  font-weight: 700;
  padding: ${InnerPadding}px;
  background: ${(props) => (props.variant === 'contained' && props.theme.palette.paperAlpha)};
  
  ${Title} { 
    font-size: ${(props) => props.variant === 'contained' ? 1.9 : 1.5}rem;
  }
`;

export const IconWrapper = styled(animated.div)`
  margin-left: 15px;
`;

export const Content = styled.div`
  padding: ${(props) => props.$padding ? `${InnerPadding}px` : 0};
`;

export const Collapsible = styled.div`
  display: flex;
  flex-direction: column;

  > ${Header} {
    color: ${(props) => props.theme.palette[props.$color] || props.theme.palette.primary};
  }
`;

export const CollapsibleGroup = styled.div`
  ${Collapsible} {
    border-top: 1px solid rgb(151, 151, 151);
    
    &:last-of-type {
      border-bottom: 1px solid rgb(151, 151, 151);
    }
  }
`;

